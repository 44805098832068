import { ref } from 'vue'
import { useLocalStorage } from '@/helpers/useLocalStorage'
import { SUPPORT_LOCALES, FALLBACK_LOCALE } from '@/lang'

const mobileMenuOpen = ref(false)

// Save locale into localstorage
const locale = useLocalStorage<SUPPORT_LOCALES>('locale')

// Locale is not set in localstorage yet so check which language the browser wants and if we support it
// Default back to {FALLBACK_LOCALE}
if (!locale.value) {
  const browserLocale = navigator.language
    ? navigator.language.split('-')[0]
    : 'en'
  locale.value = SUPPORT_LOCALES.includes(browserLocale as SUPPORT_LOCALES)
    ? (browserLocale as SUPPORT_LOCALES)
    : FALLBACK_LOCALE
}

export const useGlobalState = () => {
  const toggleMobileMenu = () => {
    mobileMenuOpen.value = !mobileMenuOpen.value
  }

  const closeMobileMenu = () => {
    mobileMenuOpen.value = false
  }

  return {
    locale,
    mobileMenuOpen,
    closeMobileMenu,
    toggleMobileMenu,
  }
}
