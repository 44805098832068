<template>
  <router-view />
</template>

<script lang="ts">
import { defineComponent, watch } from 'vue'
import { RouteLocation, useRoute, useRouter } from 'vue-router'
import { getCompanyNameById } from '@/state/data'
import { useAuthState } from '@/state/auth'
import { useHealthProviderState } from './state/healthProvider'
import { useAppState } from '@/state/app'
import { usePwaState, BeforeInstallPromptEvent } from './state/pwa'

export default defineComponent({
  setup() {
    const route = useRoute()
    const { user } = useAuthState()
    const { provider } = useHealthProviderState()
    const { sidebarIsOpen } = useAppState()
    const { promptInstallAsApp } = usePwaState()
    const router = useRouter()

    // Fetch user
    if (!user.value) {
      const { getMe } = useAuthState()
      getMe().catch(() => {
        //
      })
    }
    // Keep an eye on whether or not the user is trying to render a correct product (aanbiedermonitor)
    // If not, we redirect the user to a warning message.
    // This only applies to to internal users.
    watch(user, () => {
      if (user.value) {
        if (!user.value.is_healthcare) {
          router.replace({ name: 'Incorrect-Product' })
        }
      }
    })
    // Update page title when route changes
    const changePageTitle = (currentRoute: RouteLocation) => {
      const defaultTitle = 'A-INSIGHTS Dashboard'
      // Route provides a title
      if (currentRoute.meta.title) {
        let title = currentRoute.meta.title as string
        // Check if the title contains any {tags} and parse them
        const titleTags = title.match(/{(?<tag>.*)}/)
        if (titleTags) {
          let v = '??'
          switch (titleTags.groups?.tag) {
            // {companyName} = fetch company name from data feed
            case 'companyName':
              if (!user.value?.is_healthcare) {
                // Data feed
                v =
                  getCompanyNameById(currentRoute.params.id as string) ??
                  'Company'
              } else {
                // Legacy - make sure the title is available, otherwise just call it 'aanbieder'
                if (provider.value) {
                  if (
                    provider.value.id === (currentRoute.params.id as string)
                  ) {
                    v = provider.value?.name
                  } else {
                    v = 'Aanbieder'
                  }
                } else {
                  v = 'Aanbieder'
                }
              }
              break
          }
          title = title.replace(`{${titleTags.groups?.tag}}`, v)
        }
        document.title = `${title} | ${defaultTitle}`
      } else {
        document.title = defaultTitle
      }
    }

    // Listen for PWA install prompt and store it in the state and localstorage
    window.addEventListener('beforeinstallprompt', (event) => {
      console.log('Prepared for PWA installation.')
      event.preventDefault()
      promptInstallAsApp.value = event as BeforeInstallPromptEvent
      localStorage.setItem('deferredPrompt', JSON.stringify(event))
    })

    watch(
      route,
      (currentRoute) => {
        changePageTitle(currentRoute)
      },
      { deep: true }
    )
    return {
      // Only render scroll container is sidebar ISN't open
      sidebarIsOpen,
    }
  },
})
</script>

<style lang="scss" scoped>
.scroll-container {
  @include md-up {
    height: 100vh;
    overflow-y: auto;
  }
}
</style>
