import { routes } from './routes'
import { useAuthState } from '@/state/auth'
import { createRouter, createWebHistory } from 'vue-router'
import { useDataState } from '../state/data'
import { reportIntercomEvent, session_id } from '@/helpers/intercom'
import { usePwaState } from '@/state/pwa'
import { useAppState } from '@/state/app'

const router = createRouter({
  linkActiveClass: 'active',
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from) {
    // Scroll to top only if the url changes (not on params)
    if (to.path != from.path) {
      return { top: 0 }
    }
  },
})

// Route interceptor to check if a route is accessible by the user
router.beforeEach(async (to, from) => {
  const {
    isAuthenticated,
    isHealthcare,
    isAdmin,
    getMe,
    user,
    user_company,
    canSwitchBusinessUnits,
    updateBusinessUnit,
  } = useAuthState()
  const { user: dfUser, error } = useDataState()
  const { isAlreadyInAppMode } = usePwaState()
  const { windowSize } = useAppState()

  if (to.path === '/undefined') {
    return { path: '/' }
  }

  // Check if user is authenticated when authentication for this route is needed.
  if (to.meta && to.meta.requiresAuth && !isAuthenticated.value) {
    try {
      await getMe()
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      window.Intercom('boot', {
        api_base: 'https://api-iam.eu.intercom.io',
        app_id: 'e6ocnne7',
        name: user.value?.name,
        email: user.value?.email,
        created_at: user.value?.created_at,
        account_creation_date: user.value?.created_at,
        role: user.value?.legacy_role,
        environment: process.env.NODE_ENV,
        addons: JSON.stringify(user.value?.user_company.addons),
      })
      await reportIntercomEvent('session-start', {
        session_id,
        timestamp: Date.now(),
        source: from.query?.utm_source ?? to.query?.utm_source,
        access_mode: isAlreadyInAppMode ? 'pwa' : 'web',
        layout: `${windowSize.value}`,
        os: window.navigator?.['userAgentData']?.platform,
        browser: window.navigator?.['userAgentData']?.brands?.[0]?.brand,
      })
    } catch (e) {
      return {
        name: 'Login',
        query: {
          from: encodeURIComponent(
            window.location.pathname + window.location.search
          ),
          utm_source: to.query?.utm_source,
        },
      }
    }
  }

  // Redirect the user to /switch if they are currently linked to a holding company
  if (user.value?.user_company.is_holding === '1' && to.name !== 'Switch') {
    return { name: 'Switch' }
  }

  // Load data
  // For now this only applies to performance monitor, skip aanbiedermonitor
  if (
    user.value &&
    !isHealthcare.value &&
    !dfUser.value &&
    isAuthenticated.value &&
    !error.value &&
    to.name !== 'Switch'
  ) {
    // await loadData().catch((error) => {
    //   console.error(`Data feed error: ${error}`)
    //   router.replace({ name: 'Error' })
    // })
  }

  // Handle contract expiration for health
  if (
    user.value &&
    isHealthcare.value &&
    !user.value.user_company.is_holding &&
    (!user.value?.user_company?.contract_end ||
      new Date(user.value.user_company.contract_end) < new Date())
  ) {
    error.value = 'contract-expired'
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  window.Intercom('update', {
    api_base: 'https://api-iam.eu.intercom.io',
    app_id: 'e6ocnne7',
    name: user.value?.name,
    email: user.value?.email,
    created_at: user.value?.invited_on,
    role: user.value?.role,
    environment: process.env.NODE_ENV,
  })

  if (from.name === 'Login') {
    await reportIntercomEvent('session-start', {
      session_id,
      timestamp: Date.now(),
      source: from.query?.utm_source ?? to.query?.utm_source,
      access_mode: isAlreadyInAppMode ? 'pwa' : 'web',
      layout: `${windowSize.value}`,
      os: window.navigator?.['userAgentData']?.platform,
      browser: window.navigator?.['userAgentData']?.brands?.[0]?.brand,
    })
  }

  if (to.path != from.path) {
    reportIntercomEvent('pageview', {
      path: to.path,
      params: JSON.stringify(to.params),
      query: JSON.stringify(to.query),
      product: user.value?.is_healthcare ? 'provider' : 'performance',
      timestamp: Date.now(),
      session_id,
      access_mode: isAlreadyInAppMode ? 'pwa' : 'web',
      layout: `${windowSize.value}`,
      os: window.navigator?.['userAgentData']?.platform,
      browser: window.navigator?.['userAgentData']?.brands?.[0]?.brand,
    })
  }

  // Check for the existence of business units
  // Disable for health for now
  // Intercept hotlink to specific business unit
  if (to.query.business_unit) {
    const goalPath = to.path
    if (user.value?.user_company.is_holding === '1') {
      console.log('Hotlink requested, switching to selected business unit')
      await updateBusinessUnit(`${to.query.business_unit}`)
      await getMe()
      window.location.reload()
    } else {
      if (user.value?.user_company.parent_id) {
        // The hotlink implies we need to switch business units, however, we already are in a business unit
        if (user.value.customer_id === to.query.business_unit) {
          // It appears we are already in the right business unit, let's remove the hotlink from the route.
          return { path: goalPath }
        } else {
          // Switch to holding and try again
          console.log('Hotlink requested, switching to holding..')
          await updateBusinessUnit(null)
          await getMe()
          return {
            path: goalPath,
            query: { business_unit: to.query.business_unit },
          }
        }
      } else {
        console.error(`Error, failed business unit switch`)
      }
    }
  }

  // Before opening the product, we check if we're in a 'generic' state, such as an error, or the switch BU state.
  // Contract expiry
  if (to.name !== 'Contract-Expired' && error.value === 'contract-expired') {
    return { name: 'Contract-Expired' }
  } else if (
    (to.name === 'Contract-Expired' && error.value !== 'contract-expired') ||
    (to.name === 'Error' && !error.value)
  ) {
    // Prevent a generic page from showing when it isn't relevant.
    return { path: '/' }
  }

  // Switch Business Units
  if (
    user_company.value?.is_holding === '1' &&
    user_company.value?.parent_of &&
    to.name !== 'Switch' &&
    canSwitchBusinessUnits.value
  ) {
    return { name: 'Switch' }
  } else if (
    to.name === 'Switch' &&
    (user_company.value?.is_holding !== '1' ||
      !user_company.value?.parent_of ||
      !canSwitchBusinessUnits.value)
  ) {
    // We just switched - redirect back
    // return { path: '/' }
  }

  // If user tries to visit admin pages but isnt an admin redirect back
  if (to.meta.isAdmin && !isAdmin.value) return true
})

export default router
