export const session_id = `${Math.random().toString(36).substring(2, 20)}`
export const reportIntercomEvent = async (
  eventName: string,
  metadata?: Record<string, unknown>
) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  return await window.Intercom('trackEvent', eventName, {
    ...metadata,
    session_id,
  })
}
