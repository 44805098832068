import { ref } from 'vue'

export interface BeforeInstallPromptEvent extends Event {
  readonly platforms: string[]
  readonly userChoice: Promise<{
    outcome: 'accepted' | 'dismissed'
    platform?: string
  }>
  prompt(): Promise<void>
}

// Determine if app is already install (no banner required)
const isInStandalone =
  'standalone' in window.navigator && !!window.navigator['standalone']
const isAlreadyInAppMode =
  window.matchMedia('(display-mode: standalone)').matches ||
  isInStandalone ||
  document.referrer.includes('android-app://')

// Track if the banner has been dismissed to not show it again
const isBannerDismissed = ref<boolean>(
  !!localStorage.getItem('isBannerDismissed')
)
// Retrieve the deferred prompt from local storage if available
const deferredPromptJSON = localStorage.getItem('deferredPrompt')
const promptInstallAsApp = ref<BeforeInstallPromptEvent>(
  deferredPromptJSON ? JSON.parse(deferredPromptJSON) : undefined
)

export function usePwaState() {
  return {
    isBannerDismissed,
    isInStandalone,
    isAlreadyInAppMode,
    promptInstallAsApp,
  }
}
