import { RouteRecordRaw } from 'vue-router'

import { routes as miscRoutes } from './misc'
import { routes as authRoutes } from './auth'
import { routes as adminRoutes } from './admin'
import { routes as healthRoutes } from './aanbieder'
import { routes as exportRoutes } from './export'
import { routes as genericRoutes } from './generic'

export const routes: RouteRecordRaw[] = [
  ...healthRoutes,
  ...adminRoutes,
  ...authRoutes,
  ...exportRoutes,
  ...miscRoutes,
  ...genericRoutes,
]
