import { SelectItem } from '@/types'
import { ref, computed } from 'vue'
import { useHealthService } from '@/services/health'
// import { useHealthDashboardState } from './healthDashboard'

import { CompanyConcernResource } from '@/models/company/CompanyConcernResource'
import { ProviderResource } from '@/models/health/providers/ProviderResource'

const provider = ref<ProviderResource>()
const concern = ref<CompanyConcernResource[]>()

const isSelectYearModalOpen = ref(false)

const selectedYear = ref<string[]>([])
const providerYears = computed<SelectItem<string>[]>(() => {
  return (
    provider.value?.years
      .map((year) => {
        return { label: year, value: year }
      })
      .sort((a, b) => parseInt(b.value, 10) - parseInt(a.value, 10)) ?? []
  )
})

const exportUrl = computed<string>(() => {
  return provider.value?.export_url ?? ''
})

const isFetchingInitialState = ref(false)
const export_mode = ref(false)

// Manually set the variables
// Used when data is fetched anonymously by the export
export function setProviderData(providerData, concernData, _selectedYear) {
  provider.value = providerData
  concern.value = concernData
  selectedYear.value = _selectedYear
}

export const useHealthProviderState = () => {
  const { getSingleProvider, getSingleProviderConcern } = useHealthService()
  // const { selectedYear: globalSelectedYear } = useHealthDashboardState()

  const getState = async ({ id }: { id: string }) => {
    isFetchingInitialState.value = true
    const [providerData, concernData] = await Promise.all([
      getSingleProvider({ id }),
      getSingleProviderConcern({ id }),
    ])
    provider.value = providerData.data
    concern.value = concernData.data
    selectedYear.value = [providerData.data.years[0]]
    isFetchingInitialState.value = false
  }

  const getCompanyDataByJson = async (dataUrl: string) => {
    isFetchingInitialState.value = true
    const data = await fetch(dataUrl)
    const companyData = await data.json()
    isFetchingInitialState.value = false
    provider.value = companyData as ProviderResource
  }

  const getSelectedYear = computed(() => {
    return selectedYear.value[0]
  })

  const dataByYear = computed(() => {
    return provider.value?.data_per_year.find(
      (data) => data.year === selectedYear.value[0]
    )
  })

  return {
    provider,
    providerYears,
    selectedYear,
    getSelectedYear,
    isSelectYearModalOpen,
    isFetchingInitialState,
    dataByYear,
    concern,
    exportUrl,
    getState,
    getCompanyDataByJson,
    export_mode,
  }
}
