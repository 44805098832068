import { useGlobalState } from './global'
import { MetricPreference } from '@/types'
import { useMeService } from '@/services/me'
import { computed, readonly, ref } from 'vue'
import { useAuthService } from '@/services/auth'
import { SUPPORT_LOCALES, useLang } from '@/lang'

import { AuthUserResource } from '@/models/users/AuthUserResource'
import { MenuItemResource } from '@/models/misc/MenuItemResource'

import { LoginRequest } from '@/requests/auth/LoginRequest'
import { UserUpdateNotificationRequest } from '@/requests/users/UserUpdateNotificationRequest'
import { useRouter } from 'vue-router'
const user = ref<AuthUserResource | undefined>()
const tabs = ref<MenuItemResource[]>([])

export const useAuthState = () => {
  const authService = useAuthService()
  const meService = useMeService()
  const { locale: globalLocale } = useGlobalState()

  const { setI18nLanguage } = useLang()

  const locale = computed<SUPPORT_LOCALES>(() => {
    return user.value?.locale ?? globalLocale.value
  })

  const currencyLang = computed<string>(() => {
    return locale.value === 'en' ? 'en-GB' : 'nl-NL'
  })

  const userEmail = computed<string>(
    () => user.value?.email ?? 'unknown@a-insights.eu'
  )

  const metricPreference = computed<MetricPreference>(() => {
    return user.value?.metric_preference ?? 'EBIT'
  })

  const updateMetric = async (metric: MetricPreference) => {
    if (isAuthenticated.value && user.value) {
      user.value.metric_preference = metric

      await meService.updateUserSettings({
        body: { metric_preference: metric },
      })
    }
  }

  const updateLanguage = (locale: SUPPORT_LOCALES) => {
    if (isAuthenticated.value && user.value) {
      user.value.locale = locale

      meService.updateUserSettings({ body: { locale } })
    }
  }

  const updateNotificationSettings = async (
    body: UserUpdateNotificationRequest
  ) => {
    if (isAuthenticated.value && user.value) {
      const data = await meService.updateUserSettings({
        body: { notification_settings: body },
      })

      user.value = data.data
    }
  }

  // Used for switching business units. Permission check is done server side
  const updateBusinessUnit = async (current_bu_id?: string | null) =>
    new Promise<void>((resolve) => {
      if (isAuthenticated.value && user.value) {
        meService.updateUserSettings({ body: { current_bu_id } }).then(() => {
          resolve()
        })
      }
    })
  const canSwitchBusinessUnits = computed(() => user.value?.can_switch_bu)

  const login = (body: LoginRequest) =>
    new Promise<void>((resolve) => {
      authService.login({ body }).then((data) => {
        setI18nLanguage(data.locale)
        user.value = data
        resolve()
      })
    })

  const loginOtp = async (body: { otp: string }) => {
    const data = await authService.loginOtp({ body })
    // await getTabs()

    setI18nLanguage(data.data.locale)
    user.value = data.data
  }

  const getMe = async () =>
    new Promise<void>((resolve, reject) => {
      // If url starts with /exports, we do not fetch authentication
      if (window.location.pathname.startsWith('/exports')) reject

      meService
        .get()
        .then((data) => {
          setI18nLanguage(data.locale)
          user.value = data

          if (
            data.user_company.is_holding === '1' &&
            !window.location.href.includes('/switch')
          ) {
            window.location.href = '/switch'
          }

          resolve()
        })
        .catch((error) => {
          if (
            error === 'logged-out' &&
            !window.location.href.includes('/login') &&
            !window.location.href.includes('/exports') &&
            !window.location.href.includes('/welcome')
          ) {
            window.location.href = '/login'
          }
          reject()
        })
    })

  const logout = async () => {
    await authService.logout()
    user.value = undefined
    window.location.reload()
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    window.Intercom('shutdown')
  }

  // const getTabs = async () => {
  //   const { data } = await getHomeTabs()
  //   tabs.value = data
  // }

  const isAuthenticated = computed(() => {
    return !!user.value && !!user.value.id
  })

  const isAdmin = computed(() => {
    return user.value?.legacy_role === 'admin'
  })

  const isCompanyAdmin = computed(() => {
    return user.value?.legacy_role === 'company_admin'
  })

  const isHealthcare = computed(() => {
    return user?.value?.is_healthcare ?? false
  })

  const userAccessControl = computed(() => {
    return (
      user.value?.access_control || {
        see_dashboards: true,
        see_analysis: true,
        see_workshops: true,
        see_projects: true,
        see_other: true,
        blocked_dashboards: [],
      }
    )
  })

  const notificationSettings = computed(() => {
    return (
      user.value?.notification_settings || {
        company_insights: true,
        weekly_news: true,
        weekly_reports: true,
      }
    )
  })

  const user_company = computed(() => user.value?.user_company)

  const homeRoute = computed(() => {
    return { name: 'Health-home' }
  })

  const canAccess = (routeName: string): boolean => {
    // TODO: If tabs API is available for healthcare, remove this
    if (tabs.value.length === 0 && !isHealthcare.value) return true

    const adminRoutes = [
      'Admin',
      'Admin-customers',
      'Admin-customers-detail',
      'Admin-customers-detail-dashboard',
      'Admin-customers-detail-users',
      'Admin-customers-detail-user-detail',
      'Admin-users',
      'Admin-user-detail',
    ]

    const ACCESS_CONTROL = userAccessControl.value

    const ROUTE_IS_HOMEPAGE = ['Home', 'Health-Home'].includes(routeName)
    const ROUTE_IS_DASHBOARD =
      routeName.includes('dashboard') || routeName.includes('Dashboard')
    const ROUTE_IS_SWITCH = routeName.includes('Switch')
    const ROUTE_IS_BENCHMARK = routeName.includes('Benchmarks')
    const ROUTE_IS_COMPANY =
      routeName.includes('Companies') || routeName.includes('Company')
    const ROUTE_IS_NEWS = routeName.includes('News')
    const ROUTE_IS_TRADEFLOW = routeName.includes('Tradeflows')
    const ROUTE_IS_PROJECT = routeName.includes('Projects')
    const ROUTE_IS_COMPANY_ADMIN = ['Users', 'User-detail'].includes(routeName)
    const ROUTE_IS_ADMIN = adminRoutes.includes(routeName)
    const ROUTE_IS_EXPORT = routeName.includes('Data-Excel')

    // TODO: @Andre Fix real permissions here
    const ROUTE_IS_HEALTH = routeName.includes('Health')
    if (ROUTE_IS_HEALTH) return true

    if (ROUTE_IS_HOMEPAGE || ROUTE_IS_SWITCH || ROUTE_IS_NEWS) {
      return true
    } else if (ROUTE_IS_EXPORT) {
      return ACCESS_CONTROL.see_analysis
    } else if (ROUTE_IS_DASHBOARD) {
      return ACCESS_CONTROL.see_dashboards
    } else if (ROUTE_IS_BENCHMARK) {
      return ACCESS_CONTROL.see_analysis
    } else if (ROUTE_IS_COMPANY) {
      return ACCESS_CONTROL.see_analysis
    } else if (ROUTE_IS_TRADEFLOW) {
      return (
        !!tradeflowDashboardId.value &&
        !(user.value?.access_control.blocked_dashboards ?? []).includes(
          tradeflowDashboardId.value
        )
      )
    } else if (ROUTE_IS_PROJECT) {
      return ACCESS_CONTROL.see_projects
    } else if (ROUTE_IS_COMPANY_ADMIN) {
      return isCompanyAdmin.value || isAdmin.value
    } else if (ROUTE_IS_ADMIN) {
      return isAdmin.value
    }

    return false
  }

  const canAccessRoute = (routeName: string, access: boolean = true) => {
    return access
  }

  const tradeflowDashboardId = computed<string | undefined>(() => {
    return tabs.value?.find(
      (tab: MenuItemResource) =>
        tab.slug === 'tradeflows' && tab.content === 'show'
    )?.dashboard_id
  })

  return {
    user: readonly(user),
    isAuthenticated,
    locale,
    userEmail,
    metricPreference,
    user_company,
    isHealthcare,
    isAdmin,
    isCompanyAdmin,
    homeRoute,
    updateMetric,
    updateLanguage,
    updateBusinessUnit,
    notificationSettings,
    updateNotificationSettings,
    login,
    getMe,
    logout,
    canAccessRoute,
    canSwitchBusinessUnits,
    // tabs
    canAccess,
    tabs,
    tradeflowDashboardId,
    loginOtp,
    currencyLang,
  }
}
