import { API } from '@/api'
import { DetailResponse } from '@/types'

import { AuthUserResource } from '@/models/users/AuthUserResource'
import { UpdateUserRequest } from '@/requests/users/UpdateUserRequest'

const namespace = 'me'

export const useMeService = () => {
  const get = async () =>
    new Promise<AuthUserResource>((resolve, reject) => {
      API.get<DetailResponse<AuthUserResource>>(`${namespace}`)
        .then(({ data: { data } }) => {
          resolve(data)
        })
        .catch((error) => {
          if (error.response.status === 401) {
            reject('logged-out')
          }
          reject('request-failed')
        })
    })

  const updateUser = async ({ body }: { body: UpdateUserRequest }) => {
    const { data } = await API.post<DetailResponse<AuthUserResource>>(
      `${namespace}`,
      body
    )
    return data
  }

  const updateUserSettings = async ({ body }: { body: UpdateUserRequest }) => {
    const { data } = await API.patch<DetailResponse<AuthUserResource>>(
      `v2/${namespace}`,
      body
    )
    return data
  }

  const deleteUser = async () => {
    await API.delete(`${namespace}`)
  }

  const createOtp = async () => {
    interface OtpResource {
      otp: string
    }

    const { data } = await API.post<DetailResponse<OtpResource>>(
      `v2/${namespace}/otp`
    )

    return data
  }

  return {
    get,
    updateUser,
    deleteUser,
    updateUserSettings,
    createOtp,
  }
}
