import { RouteRecordRaw } from 'vue-router'

export const routes: RouteRecordRaw[] = [
  {
    path: '/',
    component: () => import('../views/auth/Index.vue'),
    meta: { requiresAuth: false },
    children: [
      {
        path: 'login',
        name: 'Login',
        component: () => import('../views/auth/Login.vue'),
        meta: { requiresAuth: false, title: 'Login' },
      },
      {
        path: 'auth/otp',
        name: 'LoginOtp',
        component: () => import('../views/auth/LoginOtp.vue'),
        meta: { requiresAuth: false, title: 'Login - OTP' },
      },
      {
        path: 'forgot',
        name: 'Forgot',
        component: () => import('../views/auth/Forgot.vue'),
        meta: { requiresAuth: false, title: 'Forgot Password' },
      },
      {
        path: 'reset',
        name: 'Reset',
        component: () => import('../views/auth/Reset.vue'),
        meta: { requiresAuth: false, title: 'Reset Password' },
      },
      {
        path: 'welcome',
        name: 'Welcome',
        component: () => import('../views/auth/Reset.vue'),
        meta: { requiresAuth: false, title: 'Welcome' },
      },
    ],
  },
]
