import Axios from 'axios'
import Cookies from 'js-cookie'

const baseUrl = window.location.origin.match('localhost')
  ? 'http://localhost:8000/'
  : `${window.location.origin}/`
const apiUrl = baseUrl + 'api/legacy/'
const unauthenticatedURL = baseUrl + 'backend/legacy/'

// Create API instance
let API = Axios.create({
  baseURL: apiUrl,
  withCredentials: true,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'X-XSRF-TOKEN': Cookies.get('XSRF-TOKEN'),
  },
})

let unauthenticatedAPI = Axios.create({
  baseURL: unauthenticatedURL,
  withCredentials: true,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'X-XSRF-TOKEN': Cookies.get('XSRF-TOKEN'),
  },
})

// Check if XSRF token is already set, if not set it
if (!Cookies.get('XSRF-TOKEN')) {
  Axios.create({
    baseURL: baseUrl,
    withCredentials: true,
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
      'X-XSRF-TOKEN': Cookies.get('XSRF-TOKEN'),
    },
  })
    .get('/sanctum/csrf-cookie')
    .then(() => {
      // Now that we have a CSRF token, we can create the API instance with the CSRF token set
      API = Axios.create({
        baseURL: apiUrl,
        withCredentials: true,
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'X-XSRF-TOKEN': Cookies.get('XSRF-TOKEN'),
        },
      })

      // Now that we have a CSRF token, we can create the API instance with the CSRF token set
      unauthenticatedAPI = Axios.create({
        baseURL: unauthenticatedURL,
        withCredentials: true,
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'X-XSRF-TOKEN': Cookies.get('XSRF-TOKEN'),
        },
      })
    })
}

export { API, unauthenticatedAPI }
