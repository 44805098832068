import { nextTick } from 'vue'
import { SelectItem } from '@/types'
import { createI18n } from 'vue-i18n'

export const SUPPORT_LOCALES = ['en', 'nl'] as const

export type SUPPORT_LOCALES = (typeof SUPPORT_LOCALES)[number]

export const FALLBACK_LOCALE: SUPPORT_LOCALES = 'nl'

export const languages: SelectItem<SUPPORT_LOCALES>[] = [
  { label: 'English', value: 'en' },
  { label: 'Nederlands', value: 'nl' },
]

const i18n = createI18n({
  locale: FALLBACK_LOCALE,
  fallbackLocale: FALLBACK_LOCALE,
  enableLegacy: false,
  legacy: false,
  compositionOnly: true,
  warnHtmlMessage: false,
})

export const useLang = () => {
  const supportsLocales = (locale: string): locale is SUPPORT_LOCALES => {
    return SUPPORT_LOCALES.includes(locale as SUPPORT_LOCALES)
  }

  // We can set any language here because if it doesnt exist it will fallback to the fallback locale
  const setI18nLanguage = async (locale: string) => {
    await loadLocaleMessages(locale)
    i18n.global.locale.value = locale
  }

  const loadLocaleMessages = async (locale: string) => {
    // Check if we support the locale before importing the json
    if (!supportsLocales(locale)) return

    // load locale messages with dynamic import
    const messages = await import(
      /* webpackChunkName: "locale-[request]" */ `@/lang/${locale.toLowerCase()}.json`
    )
    // set locale and locale message
    i18n.global.setLocaleMessage(locale, messages.default)

    return nextTick()
  }

  return {
    i18n,
    supportsLocales,
    setI18nLanguage,
  }
}
