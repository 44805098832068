import { RouteRecordRaw } from 'vue-router'

export const routes: RouteRecordRaw[] = [
  {
    path: '/admin',
    component: () => import('../views/admin/Index.vue'),
    meta: { requiresAuth: true, isAdmin: true, title: 'Admin' },
    children: [
      {
        path: '',
        name: 'Admin',
        component: () => import('../views/admin/Dashboard.vue'),
        meta: {
          requiresAuth: true,
          isAdmin: true,
          title: 'Admin - dashboard',
        },
      },
      {
        path: 'customers',
        name: 'Admin-customers',
        component: () => import('../views/admin/customers/Overview.vue'),
        meta: {
          requiresAuth: true,
          isAdmin: true,
          title: 'Admin - Customers',
        },
      },
      {
        path: 'customers/:customerId',
        name: 'Admin-customers-detail',
        component: () => import('../views/admin/customers/detail/Index.vue'),
        meta: {
          requiresAuth: true,
          isAdmin: true,
          title: 'Admin - Customer detail',
        },
        children: [
          {
            path: '',
            name: 'Admin-customers-detail-dashboard',
            component: () =>
              import('../views/admin/customers/detail/Dashboard.vue'),
            meta: {
              requiresAuth: true,
              isAdmin: true,
              title: 'Admin - Customer - dashboard',
            },
          },
          {
            path: 'users',
            name: 'Admin-customers-detail-users',
            component: () =>
              import('../views/admin/customers/detail/users/Overview.vue'),
            meta: {
              requiresAuth: true,
              isAdmin: true,
              title: 'Admin - Customer - users',
            },
          },
          {
            path: 'users/:userId',
            component: () =>
              import('../views/admin/customers/detail/users/Detail.vue'),
            name: 'Admin-customers-detail-user-detail',
            meta: {
              requiresAuth: true,
              isAdmin: true,
              title: 'Admin - Customer - user',
            },
          },
        ],
      },
      {
        path: 'users',
        name: 'Admin-users',
        component: () => import('../views/admin/users/Overview.vue'),
        meta: { requiresAuth: true, isAdmin: true, title: 'Admin - Users' },
      },
      {
        path: 'users/:userId',
        name: 'Admin-user-detail',
        component: () => import('../views/admin/users/Detail.vue'),
        meta: { requiresAuth: true, isAdmin: true, title: 'Admin - User' },
      },
    ],
  },
]
