import { RouteRecordRaw } from 'vue-router'
import { ProtectedRoute } from './ProtectedRoute'
import { defineAsyncComponent } from 'vue'

ProtectedRoute.components = {
  ...ProtectedRoute.components,
  HealthHome: defineAsyncComponent(() => import('@/views/aanbieder/Home.vue')),
  HealthDashboards: defineAsyncComponent(
    () => import('@/views/aanbieder/dashboards/Index.vue')
  ),
  HealthDashboard: defineAsyncComponent(
    () => import('@/views/aanbieder/dashboards/Single.vue')
  ),
  HealthLandscape: defineAsyncComponent(
    () => import('@/views/aanbieder/landscape/Index.vue')
  ),
  HealthProviders: defineAsyncComponent(
    () => import('@/views/aanbieder/provider/Overview.vue')
  ),
  HealthProvider: defineAsyncComponent(
    () => import('@/views/aanbieder/provider/detail/Index.vue')
  ),
  HealthProviderHighlights: defineAsyncComponent(
    () => import('@/views/aanbieder/provider/detail/Highlights.vue')
  ),
  HealthProviderContinuity: defineAsyncComponent(
    () => import('@/views/aanbieder/provider/detail/Continuity.vue')
  ),
  HealthProviderLegality: defineAsyncComponent(
    () => import('@/views/aanbieder/provider/detail/Legality.vue')
  ),
  HealthProviderFinancingTypes: defineAsyncComponent(
    () => import('@/views/aanbieder/provider/detail/FinancingTypes.vue')
  ),
  HealthProviderDocuments: defineAsyncComponent(
    () => import('@/views/aanbieder/provider/detail/Documents.vue')
  ),
  HealthProviderConcern: defineAsyncComponent(
    () => import('@/views/aanbieder/provider/detail/Concern.vue')
  ),
  HealthNationalComparison: defineAsyncComponent(
    () => import('@/views/aanbieder/NationalComparison.vue')
  ),
  Users: defineAsyncComponent(() => import('@/views/users/Overview.vue')),
  UserDetail: defineAsyncComponent(() => import('@/views/users/Detail.vue')),
}

export const routes: RouteRecordRaw[] = [
  {
    path: '/',
    component: () => import('@/views/aanbieder/Index.vue'),
    meta: { requiresAuth: true, isHealthcare: true },
    children: [
      {
        path: '',
        name: 'Health-home',
        component: ProtectedRoute,
        props: { componentName: 'HealthHome', routeName: 'Health-Home' },
        meta: { requiresAuth: true, isHealthcare: true, title: 'Health-home' },
      },
      {
        path: 'landscape',
        name: 'Health-landscape',
        component: ProtectedRoute,
        props: {
          componentName: 'HealthLandscape',
          routeName: 'Health-landscape',
        },
        meta: { requiresAuth: true, isHealthcare: true, title: 'Landscape' },
      },
      {
        path: 'providers',
        name: 'Health-providers',
        component: ProtectedRoute,
        props: {
          componentName: 'HealthProviders',
          routeName: 'Health-providers',
        },
        meta: { requiresAuth: true, isHealthcare: true, title: 'Providers' },
      },
      {
        path: 'dashboards',
        name: 'Health-dashboards',
        component: ProtectedRoute,
        props: {
          componentName: 'HealthDashboards',
          routeName: 'Health-dashboards',
        },
        meta: { requiresAuth: true, isHealthcare: true, title: 'Dashboards' },
        children: [
          {
            path: ':id',
            name: 'Health-dashboard',
            component: ProtectedRoute,
            props: {
              componentName: 'HealthDashboard',
              routeName: 'Health-dashboard',
            },
            meta: {
              requiresAuth: true,
              isHealthcare: true,
              title: 'Dashboard',
            },
          },
        ],
      },
      {
        path: 'providers/:id',
        name: 'Health-provider',
        component: ProtectedRoute,
        props: {
          componentName: 'HealthProvider',
          routeName: 'Health-provider',
        },
        meta: { requiresAuth: true, isHealthcare: true, title: 'Provider' },
        children: [
          {
            path: '',
            name: 'Health-provider-highlights',
            component: ProtectedRoute,
            props: {
              componentName: 'HealthProviderHighlights',
              routeName: 'Health-provider-highlights',
            },
            meta: {
              requiresAuth: true,
              isHealthcare: true,
              title: '{companyName} - Highlights',
            },
          },
          {
            path: 'continuity',
            name: 'Health-provider-continuity',
            component: ProtectedRoute,
            props: {
              componentName: 'HealthProviderContinuity',
              routeName: 'Health-provider-continuity',
            },
            meta: {
              requiresAuth: true,
              isHealthcare: true,
              title: '{companyName} - Continuity',
            },
          },
          {
            path: 'legality',
            name: 'Health-provider-legality',
            component: ProtectedRoute,
            props: {
              componentName: 'HealthProviderLegality',
              routeName: 'Health-provider-legality',
            },
            meta: {
              requiresAuth: true,
              isHealthcare: true,
              title: '{companyName} - Legality',
            },
          },
          {
            path: 'financing-types',
            name: 'Health-provider-financingtypes',
            component: ProtectedRoute,
            props: {
              componentName: 'HealthProviderFinancingTypes',
              routeName: 'Health-provider-financingtypes',
            },
            meta: {
              requiresAuth: true,
              isHealthcare: true,
              title: '{companyName} - Financing types',
            },
          },
          {
            path: 'concern',
            name: 'Health-provider-concern',
            component: ProtectedRoute,
            props: {
              componentName: 'HealthProviderConcern',
              routeName: 'Health-provider-concern',
            },
            meta: {
              requiresAuth: true,
              isHealthcare: true,
              title: '{companyName} - Concern',
            },
          },
          {
            path: 'documents',
            name: 'Health-provider-documents',
            component: ProtectedRoute,
            props: {
              componentName: 'HealthProviderDocuments',
              routeName: 'Health-provider-documents',
            },
            meta: {
              requiresAuth: true,
              isHealthcare: true,
              title: '{companyName} - Documents',
            },
          },
        ],
      },
      {
        path: 'national-comparison',
        name: 'Health-national-comparison',
        component: ProtectedRoute,
        props: {
          componentName: 'HealthNationalComparison',
          routeName: 'Health-national-comparison',
        },
        meta: {
          requiresAuth: true,
          isHealthcare: true,
          title: 'National Comparison',
        },
      },
      {
        path: 'users',
        name: 'Health-users',
        component: ProtectedRoute,
        props: { componentName: 'Users', routeName: 'Health-users' },
        meta: { requiresAuth: true, isHealthcare: true, title: 'Users' },
      },
      {
        path: 'users/:userId',
        name: 'Health-user-detail',
        component: ProtectedRoute,
        props: { componentName: 'UserDetail', routeName: 'Health-user-detail' },
        meta: { requiresAuth: true, isHealthcare: true, title: 'User' },
      },
    ],
  },
]
