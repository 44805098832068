import { RouteRecordRaw } from 'vue-router'

export const routes: RouteRecordRaw[] = [
  {
    path: '/graph',
    component: () => import('../views/graph/Index.vue'),
    meta: { requiresAuth: false },
    children: [
      {
        path: '',
        name: 'Graph',
        component: () => import('../views/graph/Graph.vue'),
        meta: { requiresAuth: false, title: 'Graph' },
      },
    ],
  },
]
