import '@/assets/sass/variables/index.scss'
import '@/assets/sass/defaults/index.scss'

import { register } from 'register-service-worker'

import App from './App.vue'
import { createApp } from 'vue'
import router from './router'
import { useLang } from './lang'
import VueClickAway from 'vue3-click-away'

const { i18n, setI18nLanguage } = useLang()
setI18nLanguage('nl')

// Create and load app
createApp(App).use(router).use(i18n).use(VueClickAway).mount('#app')

// Manage service worker & hot reloading
if (process.env.NODE_ENV !== 'development') {
  register('/service-worker.js', {
    ready() {
      console.log('App is running from cache.')
    },
    registered() {
      console.log('Service worker registered and running.')
    },
    cached() {
      console.log('App has been cached and will work faster!')
    },
    updatefound() {
      console.log('New version of the app found, downloading...')
    },
    updated(registration) {
      console.log(
        'A new version of the app has been downloaded. Activating & reloading...'
      )
      if (registration.waiting) {
        registration.waiting.postMessage({ action: 'skipWaiting' })
        window.location.reload()
      }
    },
    offline() {
      console.log(
        'No internet connection found. App is running in offline mode.'
      )
    },
    error(error) {
      console.error('Error during service worker registration:', error)
    },
  })
}
