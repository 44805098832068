import { API } from '@/api'
import { DetailResponse, IndexResponse } from './../types/index'

import { TodoResource } from '@/models/health/TodoResource'
import { BannerResource } from './../models/health/BannerResource'
import { ProviderResource } from '@/models/health/providers/ProviderResource'
import { CompanyConcernResource } from '@/models/company/CompanyConcernResource'
import { HealthDashboardInterface } from '@/models/health/HealthDashboardInterface'
import { ProviderCollectionResource } from '@/models/health/providers/ProviderCollectionResource'
import { ProviderLandscapeProductsResource } from '@/models/health/providers/ProviderLandscapeProductsResource'
import { ProviderLandscapePortfolioResource } from '@/models/health/providers/ProviderLandscapePortfolioResource'
import { HealthDashboardsInterface } from '@/models/health/HealthDashboardsInterface'

const namespace = 'v2/health'

export const useHealthService = () => {
  const getDashboard = async () => {
    const { data } = await API.get<DetailResponse<HealthDashboardInterface>>(
      `${namespace}/home`
    )
    return data
  }

  const getDashboards = async () => {
    const { data } = await API.get<DetailResponse<HealthDashboardsInterface[]>>(
      `${namespace}/dashboards`
    )
    return data
  }

  const getSingleDashboard = async (dashboardId: string) => {
    const { data } = await API.get<DetailResponse<unknown>>(
      `${namespace}/dashboards/${dashboardId}`,
      {}
    )
    return data
  }

  const getTodos = async () => {
    const { data } = await API.get<IndexResponse<TodoResource>>(
      `${namespace}/todo`
    )
    return data
  }

  const getProviders = async () => {
    const { data } = await API.get<IndexResponse<ProviderCollectionResource>>(
      `${namespace}/providers`
    )
    return data
  }

  const getProvidersBanner = async () => {
    const { data } = await API.get<IndexResponse<BannerResource>>(
      `${namespace}/providers/banner`
    )
    return data
  }

  const getSingleProvider = async ({ id }: { id: string }) => {
    const { data } = await API.get<DetailResponse<ProviderResource>>(
      `${namespace}/providers/${id}`
    )
    return data
  }

  const getSingleProviderConcern = async ({ id }: { id: string }) => {
    const { data } = await API.get<IndexResponse<CompanyConcernResource>>(
      `${namespace}/providers/${id}/concern`
    )
    return data
  }

  const getLandscapePortfolio = async () => {
    const { data } = await API.get<
      IndexResponse<ProviderLandscapePortfolioResource>
    >(`${namespace}/landscape/portfolio`)
    return data
  }

  const getLandscapeProducts = async () => {
    const { data } = await API.get<
      DetailResponse<ProviderLandscapeProductsResource>
    >(`${namespace}/landscape/products`)
    return data
  }

  return {
    getDashboard,
    getDashboards,
    getSingleDashboard,
    getTodos,
    getProviders,
    getProvidersBanner,
    getSingleProvider,
    getSingleProviderConcern,
    getLandscapePortfolio,
    getLandscapeProducts,
  }
}
