import { useAuthState } from '@/state/auth'
import { useDataState } from '@/state/data'
import { defineAsyncComponent, defineComponent, h, resolveComponent } from 'vue'

export const ProtectedRoute = defineComponent({
  name: 'ProtectedRoute',
  components: {
    NotAllowed: defineAsyncComponent(
      () => import('../views/partials/NotAllowed.vue')
    ),
    NoContract: defineAsyncComponent(() => import('@/views/NoContract.vue')),
    LoadingScreen: defineAsyncComponent(
      () => import('../views/partials/LoadingScreen.vue')
    ),
  },
  props: {
    componentName: {
      type: String,
      required: true,
    },
    routeName: {
      type: String,
      required: true,
    },
    canAccess: {
      type: Boolean,
      required: false,
      default: true,
    },
    isFetching: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup() {
    const { canAccessRoute } = useAuthState()
    const { error } = useDataState()

    return {
      canAccessRoute,
      error,
    }
  },
  render() {
    if (this.canAccessRoute(this.routeName, this.canAccess)) {
      const routeComponent = resolveComponent(this.componentName)
      return h(routeComponent)
    } else if (this.isFetching) {
      const LoadingScreen = resolveComponent('LoadingScreen')
      return h(LoadingScreen)
    } else {
      const notAllowedComponent = resolveComponent('NotAllowed')
      return h(notAllowedComponent)
    }
  },
})
