import {
  CompanyType,
  CustomerType,
  DashboardType,
  MetricType,
  ProductDocumentType,
  UserType,
} from '@/types'
import { computed, readonly, ref } from 'vue'
import { SelectItem } from '@/types'
import { getMetricInfo } from './metricInfo'
import { sortedUniq } from 'lodash'

const error = ref<string>()
const user = ref<UserType | undefined>()
const customer = ref<CustomerType | undefined>()
const companies = ref<{ [key: string]: CompanyType } | undefined>()
const metrics = ref<
  { [companyKey: string]: { [metricKey: string]: MetricType } } | undefined
>()
const avgMetrics = ref<
  | {
      [companyKey: string]: {
        [metricKey: string]: { [currencyName: string]: number } | number
      }
    }
  | undefined
>()
const companyYears = ref<number[]>()
const customer_company_years = ref<number[]>()
const companyLogos = ref<{ [key: string]: string } | undefined>()
const dashboards = ref<DashboardType[]>()
const baseUrl = ref<string>()
const product_documents = ref<ProductDocumentType[]>()

// Global state for the data feed
export const useDataState = () => {
  // Calculate the years available in the dataset for the customer within all metrics
  const years = computed<string[]>(() =>
    (companyYears.value ?? []).sort().map((year) => `${year}`)
  )
  const yearsDropdown = computed<SelectItem<string>[]>(() =>
    (years.value ?? []).reverse().map((year) => ({
      label: year,
      value: year,
    }))
  )
  const customerAVG3Y = computed<number[] | undefined>(() =>
    customer_company_years.value?.slice(-3)
  )

  // Calculate the highest year in which > 55% of company data is available
  const fiftyFivePercentYear = computed(() => {
    let resultYear = 0
    const totalCompanies = Object.keys(companies.value ?? {}).length
    Object.keys(metricsPerYear.value)
      .sort()
      .forEach((year) => {
        const percentageAvailable =
          Object.keys(metricsPerYear.value[year]).length / totalCompanies

        if (percentageAvailable > 0.55) resultYear = parseInt(year)
      })

    return resultYear
  })

  // Transform the company -> metric -> year object into a year -> company -> metric object
  const metricsPerYear = computed(() => {
    const result = {}
    Object.keys(metrics.value ?? {}).forEach((companyId) => {
      Object.keys(metrics.value?.[companyId] ?? {}).forEach((metricId) => {
        if (!getMetricInfo(metricId)?.is_special && metricId !== '_meta') {
          Object.keys(metrics.value?.[companyId]?.[metricId] ?? {}).forEach(
            (year) => {
              if (!result[year]) result[year] = {}
              if (!result[year][companyId]) result[year][companyId] = {}

              result[year][companyId][metricId] =
                metrics.value?.[companyId]?.[metricId]?.[year]
            }
          )
        }
      })
    })
    return result
  })

  // const get = () =>
  //   new Promise<DataFeedResponseType>((resolve, reject) => {
  //     // loadDataFeed()
  //     //   .then(async (response) => {
  //     //     if (!response.data.data && !response.data.error) {
  //     //       user.value = response.data.user
  //     //       customer.value = response.data.customer
  //     //       companies.value = response.data.companies
  //     //       dashboards.value = response.data.dashboards
  //     //       metrics.value = response.data.metrics
  //     //       avgMetrics.value = response.data['avg_metrics']
  //     //       baseUrl.value = response.data.base_url
  //     //       companyYears.value = response.data.years
  //     //       customer_company_years.value = response.data.customer_company_years
  //     //       product_documents.value = response.data.product_documents
  //     //       transformData()
  //     //       // Resolving the promise causes the UI to become responsive. We have all the data we require at this point.
  //     //       resolve(response.data)
  //     //       // From here on we can execute asynchronous tasks.
  //     //       const logos = await getLogos()
  //     //       if (typeof logos.data === 'object') {
  //     //         companyLogos.value = logos.data.logos
  //     //       }
  //     //     } else {
  //     //       const errorMessage = response.data.error ?? response.data.data
  //     //       error.value = errorMessage
  //     //       reject(errorMessage)
  //     //     }
  //     //   })
  //     //   .catch((error) =>
  //     //     reject(`Data feed error -${error.status} - ${error.message}`)
  //     //   )
  //   })

  // const transformData = () => {
  //   // Transform date strings into date objects
  //   Object.keys(companies.value ?? {}).forEach((companyId) => {
  //     const company = companies.value?.[companyId]
  //     Object.keys(company?.news ?? {}).map((newsLanguage) => {
  //       ;(company?.news?.[newsLanguage] ?? []).map((newsArticle, index) => {
  //         if (companies.value)
  //           companies.value[companyId].news[newsLanguage][index].published =
  //             parseISO(newsArticle.published)
  //       })
  //     })
  //   })
  // }

  const set = (data) => {
    user.value = data.user
    customer.value = data.customer
    companies.value = data.companies
    dashboards.value = data.dashboards
    metrics.value = data.metrics
    avgMetrics.value = data['avg_metrics']
    baseUrl.value = data.base_url
    companyYears.value = data.years
    customer_company_years.value = data.customer_company_years
  }

  return {
    user: readonly(user),
    customer: readonly(customer),
    companies: readonly(companies),
    metrics: readonly(metrics),
    avgMetrics: readonly(avgMetrics),
    companyLogos: readonly(companyLogos),
    error,
    baseUrl: readonly(baseUrl),
    customerAVG3Y: readonly(customerAVG3Y),
    years: readonly(years),
    dashboards: readonly(dashboards),
    product_documents: readonly(product_documents),
    get: () => {
      // Removed
    },
    set,
    yearsDropdown,
    fiftyFivePercentYear: readonly(fiftyFivePercentYear),
    customer_company_years: readonly(customer_company_years),
    // Computed
    // All metrics for all companies
    allCustomerMetrics: computed(() =>
      sortedUniq(
        Object.keys(metrics.value ?? {})
          .map((companyId) => Object.keys(metrics.value?.[companyId] ?? {}))
          .flat()
          .sort()
      )
    ),
  }
}

// Helpers
export const getMaxYearForCompany = (companyId: string) => {
  let maxYear = 0
  Object.keys(metrics.value?.[companyId] ?? {}).map((metricName) => {
    Object.keys(metrics.value?.[companyId]?.[metricName] ?? {}).map((year) => {
      if (parseInt(year) > maxYear) maxYear = parseInt(year)
    })
  })
  return maxYear
}

export const getCompanyNameById = (companyId?: string) =>
  companyId && companies.value?.[companyId]?.display_name

export const getCompanyYears = (companyId: string) => {
  const foundYears: number[] = []
  Object.keys(metrics.value?.[companyId] ?? {}).map((metricName) => {
    Object.keys(metrics.value?.[companyId][metricName] ?? {}).map((year) => {
      if (!foundYears.includes(parseInt(year)) && !isNaN(parseInt(year))) {
        foundYears.push(parseInt(year))
      }
    })
  })

  return foundYears.sort()
}
