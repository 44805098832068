import { computed, ref, watch } from 'vue'
import { TabType } from '@/components/Tabs.vue'

export type WindowSizeType = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl'

const sizes = { xs: 0, sm: 600, md: 900, lg: 1200, xl: 1400, xxl: 1800 }

// This hook contains information about
// - Window: did the user scroll?
// - Window: What width do we have available, plus a bunch of convenience accessor.

// Check if user scrolled
window.addEventListener(
  'scroll',
  () => {
    didScroll.value =
      (document.documentElement.scrollTop || document.body.scrollTop) > 100
  },
  true
)
const didScroll = ref<boolean>(
  (document.documentElement.scrollTop || document.body.scrollTop) > 100
)
const windowHeight = ref(2000)

// Window size
const getWindowSize: () => WindowSizeType = () => {
  windowHeight.value = window.innerHeight
  if (window.innerWidth > sizes.xxl) {
    return 'xxl'
  } else if (window.innerWidth > sizes.xl) {
    return 'xl'
  } else if (window.innerWidth > sizes.lg) {
    return 'lg'
  } else if (window.innerWidth > sizes.md) {
    return 'md'
  } else if (window.innerWidth > sizes.sm) {
    return 'sm'
  } else {
    return 'xs'
  }
}
const windowSize = ref<WindowSizeType>(getWindowSize())
window.addEventListener('resize', () => (windowSize.value = getWindowSize()))

const windowIs = (
  size: WindowSizeType,
  comparison?: 'or up' | 'exactly' | 'or down'
) => {
  if (comparison === 'exactly' || !comparison) {
    return size === windowSize.value
  } else if (comparison === 'or down') {
    if (size === 'xxl') {
      return ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'].includes(windowSize.value)
    } else if (size === 'xl') {
      return ['xs', 'sm', 'md', 'lg', 'xl'].includes(windowSize.value)
    } else if (size === 'lg') {
      return ['xs', 'sm', 'md', 'lg'].includes(windowSize.value)
    } else if (size === 'md') {
      return ['xs', 'sm', 'md'].includes(windowSize.value)
    } else if (size === 'sm') {
      return ['xs', 'sm'].includes(windowSize.value)
    } else if (size === 'xs') {
      return ['xs'].includes(windowSize.value)
    }
  } else if (comparison === 'or up') {
    if (size === 'xxl') {
      return ['xxl'].includes(windowSize.value)
    } else if (size === 'xl') {
      return ['xl', 'xxl'].includes(windowSize.value)
    } else if (size === 'lg') {
      return ['lg', 'xl', 'xxl'].includes(windowSize.value)
    } else if (size === 'md') {
      return ['md', 'lg', 'xl', 'xxl'].includes(windowSize.value)
    } else if (size === 'sm') {
      return ['sm', 'md', 'lg', 'xl', 'xxl'].includes(windowSize.value)
    } else if (size === 'xs') {
      return ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'].includes(windowSize.value)
    }
  }
}

// Returns true if the current window size matches one of the given
const windowIsOneOf = (options: WindowSizeType[]) =>
  options.includes(windowSize.value)

// Convenience properties
const isXSOrBigger = computed(() =>
  ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'].includes(windowSize.value)
)
const isSMOrBigger = computed(() =>
  ['sm', 'md', 'lg', 'xl', 'xxl'].includes(windowSize.value)
)
const isMDOrBigger = computed(() =>
  ['md', 'lg', 'xl', 'xxl'].includes(windowSize.value)
)
const isLGOrBigger = computed(() =>
  ['lg', 'xl', 'xxl'].includes(windowSize.value)
)
const isXLOrBigger = computed(() => ['xl', 'xxl'].includes(windowSize.value))
const isXXLOrBigger = computed(() => ['xxl'].includes(windowSize.value))
const isXSOrSmaller = computed(() => ['xs'].includes(windowSize.value))
const isSMOrSmaller = computed(() => ['xs', 'sm'].includes(windowSize.value))
const isMDOrSmaller = computed(() =>
  ['xs', 'sm', 'md'].includes(windowSize.value)
)
const isLGOrSmaller = computed(() =>
  ['xs', 'sm', 'md', 'lg'].includes(windowSize.value)
)
const isXLOrSmaller = computed(() =>
  ['xs', 'sm', 'md', 'lg', 'xl'].includes(windowSize.value)
)
const isXXLOrSmaller = computed(() =>
  ['xs', 'sm', 'md', 'lg', 'xxl'].includes(windowSize.value)
)

// Interface status
const sidebarIsOpen = ref(false)
const dialogIsOpen = ref(false)

// Hide document scrollbar if sidebar is open
watch(sidebarIsOpen, () => {
  if (sidebarIsOpen.value) {
    document.body.style.overflow = 'hidden'
  } else {
    document.body.style.overflow = 'visible'
  }
})

// Reflect page state
const pageTitle = ref()
const pageActions = ref<TabType[]>()

export const useAppState = () => {
  // Convenience function for comparison
  return {
    // Scroll boolean
    didScroll,
    // Window size + convenience accessors
    windowSize,
    windowHeight,
    windowIs,
    windowIsOneOf,
    isXSOrBigger,
    isSMOrBigger,
    isMDOrBigger,
    isLGOrBigger,
    isXLOrBigger,
    isXXLOrBigger,
    isXSOrSmaller,
    isSMOrSmaller,
    isMDOrSmaller,
    isLGOrSmaller,
    isXLOrSmaller,
    isXXLOrSmaller,
    // Interface status
    sidebarIsOpen,
    dialogIsOpen,
    // Page status
    pageTitle,
    pageActions,
  }
}
