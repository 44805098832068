import { API, unauthenticatedAPI } from '@/api'
import { DetailResponse } from '@/types'

import { LoginRequest } from '@/requests/auth/LoginRequest'
import { ChangePasswordRequest } from '@/requests/auth/ChangePasswordRequest'
import { ForgotPasswordRequest } from '@/requests/auth/ForgotPasswordRequest'

import { ResetPasswordRequest } from '@/requests/auth/ResetPasswordRequest'
import { AuthUserResource } from '@/models/users/AuthUserResource'

const namespace = 'auth'

export const useAuthService = () => {
  const login = async ({ body }: { body: LoginRequest }) => {
    const { data } = await API.post<AuthUserResource>(
      `${namespace}/login`,
      body
    )
    return data
  }

  const loginOtp = async ({ body }: { body: { otp: string } }) => {
    const { data } = await API.post<DetailResponse<AuthUserResource>>(
      `${namespace}/login_otp`,
      body
    )

    return data
  }

  const logout = async () => {
    await API.post(`/logout`)
    return
  }

  const resetPassword = async ({ body }: { body: ResetPasswordRequest }) => {
    await API.post(`${namespace}/reset`, body)
    return
  }

  const forgotPassword = async ({ body }: { body: ForgotPasswordRequest }) => {
    await unauthenticatedAPI.post(`forgot-password`, body)
    return
  }

  const changePassword = async ({ body }: { body: ChangePasswordRequest }) => {
    await API.post(`users/change_password`, body)
    return
  }

  return {
    login,
    logout,
    resetPassword,
    forgotPassword,
    changePassword,
    loginOtp,
  }
}
