import { RouteRecordRaw } from 'vue-router'
import { ProtectedRoute } from './ProtectedRoute'
import { defineAsyncComponent } from 'vue'

ProtectedRoute.components = {
  ...ProtectedRoute.components,
  ContractExpired: defineAsyncComponent(
    () => import('@/views/generic/ContractExpired.vue')
  ),
  SwitchBusinessUnit: defineAsyncComponent(
    () => import('@/views/generic/SwitchBusinessUnit.vue')
  ),
  DataFeedError: defineAsyncComponent(
    () => import('@/views/generic/DataFeedError.vue')
  ),
  IncorrectProduct: defineAsyncComponent(
    () => import('@/views/generic/IncorrectProduct.vue')
  ),
}

export const routes: RouteRecordRaw[] = [
  {
    path: '/contract-expired',
    name: 'Contract-Expired',
    component: ProtectedRoute,
    props: { componentName: 'ContractExpired', routeName: 'Contract-Expired' },
    meta: { requiresAuth: true, title: 'Contract Expired' },
  },
  {
    path: '/switch',
    name: 'Switch',
    component: ProtectedRoute,
    props: { componentName: 'SwitchBusinessUnit', routeName: 'Switch' },
    meta: { requiresAuth: true, title: 'Switch' },
  },
  {
    path: '/error',
    name: 'Error',
    component: ProtectedRoute,
    props: { componentName: 'DataFeedError', routeName: 'Error' },
    meta: { requiresAuth: true, title: 'We encountered a problem!' },
  },
  {
    path: '/incorrect-product',
    name: 'Incorrect-Product',
    component: ProtectedRoute,
    props: {
      componentName: 'IncorrectProduct',
      routeName: 'Incorrect-Product',
    },
    meta: { requiresAuth: true, title: 'Incorrect Product' },
  },
]
